import { Link } from 'gatsby';
import React from 'react';
import SiteConfig from '../../../data/SiteConfig';

export const ALink = ({ href, children }) => (
  <a
    target="_blank"
    href={`${href}`}
    rel="noopener noreferrer"
    className="font-semibold text-gray-400 text-xs font-mono hover:underline"
  >
    {children}
  </a>
);

const PermaLink = ({ to }) => (
  <span className="text-gray-600 font-display text-sm mb-4">
    Permalink:{' '}
    <Link to={to} className="font-semibold font-mono hover:underline">
      {`${SiteConfig.siteUrlShort}${to}`}
    </Link>
  </span>
);

export default PermaLink;
