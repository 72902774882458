/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';

const IconBadge = ({ children, icon, iconTwo, noBackground }) => {
  const propClasses = classNames({
    '': noBackground,
    'bg-primary-100 border border-primary-200': !noBackground,
  });

  const iconPrimary = classNames({
    'fa-ban text-gray-400 opacity-50': icon === 'ban',
    'fa-book-reader': icon === 'reader',
    'fa-donate': icon === 'donate',
    'fa-dumbbell': icon === 'dumbbell',
    'fa-hands-helping': icon === 'hands',
    'fa-laptop': icon === 'laptop',
    'fa-square': icon === 'square',
  });

  const iconSecondary = classNames({
    'fa-ban': iconTwo === 'ban',
    'fa-donate text-gray-600': iconTwo === 'donate',
    'fa-paint-brush ml-1': iconTwo === 'brush',
    'fa-terminal fa-inverse': iconTwo === 'terminal',
  });

  const iconAlignment = classNames({
    '': iconTwo,
    'items-center': !iconTwo,
  });

  return (
    <span
      className={`p-1 ${propClasses} ${iconAlignment} md:inline inline-flex justify-center text-gray-500 rounded font-mono text-xs`}
      css={css`
        width: 12.5rem;
      `}
    >
      <span
        className="fa-stack mr-2"
        data-tip="Non-affiliate link."
        data-for="non-affiliate"
        data-effect="solid"
      >
        <i className={`fa ${iconSecondary} fa-stack-1x fa-lg`} />
        <i className={`fa ${iconPrimary} fa-stack-1x fa-2x`} />
        <ReactTooltip id="non-affiliate" />
      </span>
      {children}
    </span>
  );
};

export const ReadTimeBadge = ({ timeToRead, noBackground, noIcon }) => {
  const propClasses = classNames({
    '': noBackground === true,
    'bg-gray-600 rounded-sm': !noBackground,
  });

  return (
    <span
      className={`font-mono text-sm ${propClasses} text-white px-2 py-1 mb-1 inline-block whitespace-no-wrap`}
    >
      {' '}
      {!noIcon && <i className="far fa-clock fa- text-white mr-2" />}
      Reading Time: {timeToRead} minutes
    </span>
  );
};

export default IconBadge;
