import ColorContrastChecker from './color-contrast-checker';

const ccc = new ColorContrastChecker();

export function generateBookPalette(data) {
  const tagColorSelection = [
    { background: data.vibrant, foreground: data.darkVibrant },
    { background: data.lightVibrant, foreground: data.darkMuted },
    { background: data.vibrant, foreground: data.darkMuted },
    { background: data.vibrant, foreground: data.muted },
    { background: data.darkVibrant, foreground: data.vibrant },
    { background: data.darkMuted, foreground: data.vibrant },
  ];
  const metaColorSelection = [
    { background: data.darkMuted, foreground: data.lightMuted },
    { background: data.darkMuted, foreground: data.muted },
    { background: data.darkMuted, foreground: data.darkVibrant },
    { background: data.darkMuted, foreground: data.vibrant },
    { background: data.darkMuted, foreground: data.lightVibrant },
  ];
  const metaColorSelectionFallback = [
    { background: data.lightMuted, foreground: data.muted },
    { background: data.lightMuted, foreground: data.darkMuted },
    { background: data.lightMuted, foreground: data.lightVibrant },
  ];
  const tagColorPick = ccc.getHighestRatioForPairs(tagColorSelection);
  let metaColorPick = ccc.getHighestRatioForPairs(metaColorSelection);
  if (metaColorPick && metaColorPick.ratio < 2.8) {
    metaColorPick = ccc.getHighestRatioForPairs(metaColorSelectionFallback);
  }

  return {
    metaColorPick,
    tagColorPick,
    tagColorSelection,
  };
}
export default generateBookPalette;
