/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from '../components/Layout';
import NewsletterWide from '../components/NewsletterFormSidebar';
import SEO from '../components/seo/seo';
import BookHeader from '../components/template/BookHeader';
import ShareArticle from '../components/template/ShareArticle';
import SimilarBooks from '../components/template/SimilarBooks';
import Avatar from '../components/ui/avatar/avatar';
import IconBadge from '../components/ui/badge/icon-badge';
import Button from '../components/ui/button/button';
import Card from '../components/ui/card/card';
import CardNav from '../components/ui/card/cardNav';
import Divider from '../components/ui/divider/divider';
import { ALink } from '../components/ui/link/link';
import SiteConfig from '../data/SiteConfig';

export default function BookTemplate({ data: { book, prevBook, nextBook } }) {
  const { frontmatter, motivation, quotes, excerpt, reasons } = book;

  const PrevCard = prevBook
    ? () => (
        <Card
          prev
          title={prevBook.frontmatter.title}
          slug={prevBook.frontmatter.slug}
          sharpFluid={prevBook.frontmatter.image.childImageSharp.gatsbyImageData}
        />
      )
    : null;
  const NextCard = nextBook
    ? () => (
        <Card
          next
          title={nextBook.frontmatter.title}
          slug={nextBook.frontmatter.slug}
          sharpFluid={nextBook.frontmatter.image.childImageSharp.gatsbyImageData}
        />
      )
    : null;

  return (
    <Layout progress>
      <SEO frontmatter={frontmatter} isBlogPost />
      <BookHeader
        post={frontmatter}
        image={frontmatter.image}
        title={frontmatter.title}
        bookAuthor={frontmatter.bookAuthor}
        rating={frontmatter.rating}
        readEase={frontmatter.readEase}
        readTime={frontmatter.readTime}
        readOn={frontmatter.readOn}
        publicationYear={frontmatter.publicationYear}
        read={frontmatter.read}
        pages={frontmatter.pages}
        categories={frontmatter.categories}
      />
      <div className="container pt-8 pb-12 font-body">
        <div className="flex flex-wrap">
          <div className="md:w-3/4 w-full md:pr-6">
            <section
              className="flex justify-start items-center content-center"
              css={css`
                color: var(--textLabel);
              `}
            >
              <Avatar />
              <div className="flex flex-col">
                <span className="font-sans text-sm font-semibold ml-2">by Juvoni Beckford</span>
                <a
                  className="font-sans text-xs font-semibold text-gray-500 ml-2 tracking-wider hover:text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.twitter.com/juvoni"
                >
                  @juvoni
                </a>
              </div>
            </section>
            <div className="clearfix">
              {' '}
              <MDXRenderer>{excerpt.body}</MDXRenderer>
            </div>
            {motivation && (
              <>
                {' '}
                <h2>
                  <i className="fa fa-puzzle-piece text-gray-500 mr-4 inline-block" />
                  Motivations to Read
                </h2>
                <MDXRenderer>{motivation.body}</MDXRenderer>
              </>
            )}
            {reasons && (
              <React.Fragment>
                {' '}
                <h2>
                  <i className="fas fa-balance-scale-left text-gray-500 mr-4 inline-block" />3
                  Reasons to Read
                </h2>
                <MDXRenderer>{reasons.body}</MDXRenderer>
              </React.Fragment>
            )}
            {quotes && (
              <React.Fragment>
                <h2>
                  <i className="fa fa-quote-left text-gray-500 mr-4 inline-block" />
                  Notable Quotes
                </h2>
                <div
                  css={css`
                    blockquote p {
                      &::before {
                        display: inline-block;
                        margin-right: 0.75rem;
                        font-size: 1.3em;
                        font-family: 'Font Awesome 5 Free';
                        font-weight: 900;
                        content: '\f10d';
                        color: #afbaca;
                      }
                    }
                  `}
                >
                  <MDXRenderer>{quotes.body}</MDXRenderer>
                </div>
              </React.Fragment>
            )}
            {book.content.wordCount.words !== null ? (
              <React.Fragment>
                {' '}
                <h2 className="mt-10">{frontmatter.title} Notes & Summary</h2>
                <div className="font-sans">
                  <MDXRenderer>{book.content.body}</MDXRenderer>
                </div>
                {/* <AmazonBar link={content.frontmatter.bookLink} /> */}
              </React.Fragment>
            ) : (
              <blockquote
                className="mt-12 py-4 text-sm bg-gray-100 font-mono"
                css={css`
                  color: var(--textNormal);
                  background-color: var(--bgBadge);
                `}
              >
                <b>Notes for this book are still being transcribed.</b>
                <hr />
                Follow{' '}
                <a
                  className="text-blue-500"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="http://www.twitter.com/juvoni"
                >
                  @juvoni <i className="fab fa-twitter" />
                </a>{' '}
                for more info. Send me your hidden gem <i className="far fa-gem" /> book
                recommendations.
              </blockquote>
            )}
            <div className="flex">
              <span className="font-sans text-sm ml-2 text-gray-500">
                {`posted ${frontmatter.date}`}
              </span>
            </div>

            <CardNav Prev={PrevCard} Next={NextCard} />
            <hr
              className="border-b border-gray-300 my-12 block"
              css={css`
                border-color: var(--border);
              `}
            />
          </div>
          <div className="md:w-1/4 w-full md:pl-6">
            <div className="flex flex-col items-center">
              <Button
                amazon
                external={`${frontmatter.bookLink}?tag=${SiteConfig.booksAffiliateLink}`}
              >
                Buy on Amazon
              </Button>
              {frontmatter.goodreads && (
                <Button goodreads external={frontmatter.goodreads}>
                  View Goodreads
                </Button>
              )}
              <div className="mt-6">
                <ALink href={frontmatter.bookLink}>
                  <IconBadge icon="ban" iconTwo="donate" noBackground>
                    Direct Amazon Link
                  </IconBadge>
                </ALink>
              </div>
            </div>
            <Divider position="bottom" />
            <SimilarBooks
              categories={frontmatter.categories}
              currentBookSlug={frontmatter.slug}
              tags={frontmatter.tags || []}
            />
            <Divider position="bottom" />
            <NewsletterWide />
            <Divider position="bottom" />
            <ShareArticle post={book} center />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($id: String, $prevId: String, $nextId: String) {
    book(id: { eq: $id }) {
      frontmatter {
        date(formatString: "LL")
        read(formatString: "LL")
        title
        layout
        author
        slug
        goodreads
        page
        bookAuthor
        bookLink
        categories
        publicationYear
        isbn
        asin
        pages
        rating
        readEase
        readTime
        readOn
        image {
          childImageSharp {
            gatsbyImageData(width: 196, height: 293, formats: [AUTO, WEBP])
          }
        }
      }
      content {
        body
        wordCount {
          words
        }
      }
      excerpt {
        body
      }
      quotes {
        body
      }
      motivation {
        body
      }
      reasons {
        body
      }
    }
    prevBook: book(id: { eq: $prevId }) {
      frontmatter {
        title
        slug
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
          }
        }
      }
    }
    nextBook: book(id: { eq: $nextId }) {
      frontmatter {
        title
        slug
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, formats: [AUTO, WEBP])
          }
        }
      }
    }
  }
`;
