import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { useId } from 'react-id-generator';
import getPostsFromQuery from '../../utils/graphql-utils';
import Related from '../ui/related/related';
import SimilarPostsFactory from './SimilarPostsFactory';

const SimilarBooksComponent = ({ books }) => {
  const similarBookKeys = useId(books.length);

  return (
    <section>
      <h3 className="uppercase text-xl text-gray-600 tracking-wide text-center">Related Books</h3>
      <div className="flex justify-between">
        {books.map((book, i) => {
          const { title, slug, image } = book.article;
          return (
            <Related
              title={title}
              slug={slug}
              image={image.childImageSharp.gatsbyImageData}
              key={similarBookKeys[i]}
            />
          );
        })}
      </div>
    </section>
  );
};

// (1.) Query for books
export default (props) => (
  <StaticQuery
    query={graphql`
      query SimilarBooks {
        books: allBook(sort: { fields: frontmatter___date, order: DESC }) {
          nodes {
            id
            frontmatter {
              date
              read(formatString: "LL")
              readOrder: read
              title
              author
              slug
              page
              image {
                childImageSharp {
                  gatsbyImageData(width: 160, height: 240, layout: FIXED, formats: [AUTO, WEBP])
                }
              }
              bookAuthor
              bookLink
              isbn
              pages
              rating
              readEase
              readTime
              categories
            }
          }
        }
      }
    `}
    render={(data) => {
      const { categories, tags, currentBookSlug } = props;

      // (2.) Marshall the response into articles
      const posts = getPostsFromQuery(data.books);
      // (3.) Use a SimilarArticlesFactory to get my similar articles
      const similarBooks = new SimilarPostsFactory(posts, currentBookSlug)
        .setMaxPosts(3)
        .setCategories(categories)
        .setTags(tags)
        .getPosts();

      // (4.) Render it
      return <SimilarBooksComponent books={similarBooks} />;
    }}
  />
);
