/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Link } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { memo } from 'react';
import { useId } from 'react-id-generator';
import ReactTooltip from 'react-tooltip';
import slugify from 'slugify';
import usePalette from '../../hooks/use-palette';
import { generateBookPalette } from '../../utils/color-util';
import BookMeta, { BookMedium, BookRatingMeta } from '../ui/book/bookUi';

export default memo(
  ({
    image,
    title,
    bookAuthor,
    rating,
    readTime,
    readEase,
    readOn,
    publicationYear,
    read,
    pages,
    isbn,
    categories,
  }) => {
    const { gatsbyImageData } = image.childImageSharp;
    const data = usePalette(gatsbyImageData.images.fallback.src);

    const readbilityLevels = ['Easy', 'Standard', 'Intermediate', 'Difficult'];

    const { metaColorPick, tagColorPick, tagColorSelection } = generateBookPalette(data);

    const idList = useId(4);
    const categoryKeyList = useId(2);

    const style = { background: data.vibrant };

    return (
      <section style={style}>
        <BackgroundImage fluid={['linear-gradient(rgba(5,15,10,.1),rgba(5,15,10,.6))']}>
          <div className="container flex flex-col md:flex-row py-10 md:py-14 items-center">
            <div className="flex">
              <GatsbyImage
                image={gatsbyImageData}
                className="rounded shadow-lg md:mr-12"
                alt={`${title} Cover`}
              />
            </div>
            <div className="container md:px-0">
              <h1 className="font-display leading-snug text-white font-bold text-2xl md:text-4xl mt-0 mb-1 text-center md:text-left">
                {title}
              </h1>
              {bookAuthor && (
                <span className="text-center md:text-left">
                  <span className="text-gray-400 text-sm mr-2 font-sans">by</span>
                  <span className="font-semibold font-display text-xl pr-5">
                    <span style={{ color: data.lightVibrant }}>{bookAuthor}</span>
                  </span>
                </span>
              )}
              <div className="mt-3 md:mt-4 flex md:block flex-col md:flex-row w-full">
                {rating && (
                  <BookRatingMeta color={data} label="Rating" suffix="/10">
                    <span className="text-2xl md:text-4xl">{rating}</span>
                  </BookRatingMeta>
                )}
                {readEase && (
                  <BookRatingMeta color={data} label="Readability">
                    <span
                      data-tip={readbilityLevels[4 - readEase]}
                      data-for="readbility-tip"
                      data-effect="solid"
                    >
                      <ReactTooltip id="readbility-tip" />
                      {Array.from({ length: 4 }).map((_, i) => (
                        <i
                          key={idList[i]}
                          className={`${readEase > i ? 'fa' : 'far'} fa-circle text-xs mr-1`}
                        />
                      ))}
                    </span>
                  </BookRatingMeta>
                )}
                {readTime && (
                  <BookRatingMeta color={data} label="Read Time" suffix="hrs">
                    {readTime}
                  </BookRatingMeta>
                )}
                {readOn && (
                  <BookRatingMeta color={data} label="Readible On" last contents>
                    <BookMedium readOn={readOn} />
                  </BookRatingMeta>
                )}
              </div>
              <div
                className="mt-2 flex flex-col md:inline-block"
                css={css`
                  b {
                    color: ${metaColorPick && metaColorPick.ratio < 2.8
                      ? `${tagColorSelection.foreground} !important`
                      : ''};
                  }
                `}
              >
                {publicationYear && (
                  <BookMeta
                    label="Published"
                    metaColorPick={metaColorPick}
                    iconClassName="fa-book-open"
                  >
                    {publicationYear}
                  </BookMeta>
                )}
                {read && (
                  <BookMeta
                    label="Read"
                    metaColorPick={metaColorPick}
                    iconClassName="fa-calendar-check"
                  >
                    {read}
                  </BookMeta>
                )}
                {pages && (
                  <BookMeta label="Pages" metaColorPick={metaColorPick} iconClassName="fa-file">
                    {pages}
                  </BookMeta>
                )}
                {isbn && (
                  <BookMeta label="ISBN" metaColorPick={metaColorPick} iconClassName="fa-globe">
                    {isbn}
                  </BookMeta>
                )}
              </div>
              <div className="mt-6">
                {categories &&
                  categories.slice(0, 2).map((item, i) => (
                    <Link
                      to={`/book-category/${slugify(item).toLowerCase()}`}
                      key={categoryKeyList[i]}
                    >
                      <span
                        className="inline-block px-3 py-1 mr-2 text-xs font-mono mb-1 rounded font-semibold uppercase tracking-wide"
                        css={css`
                          color: ${tagColorPick && tagColorPick.foreground};
                          background: ${tagColorPick && tagColorPick.background};
                        `}
                      >
                        {item}
                      </span>
                    </Link>
                  ))}
              </div>
              <div className="flex md:justify-end md:mr-8 justify-start">
                <Link
                  to="/books"
                  className="font-mono items-center whitespace-no-wrap text-xs rounded font-semibold uppercase border px-3 py-2 mt-4"
                  css={css`
                    color: rgba(255, 255, 255, 0.8);
                    border-color: ${tagColorPick && tagColorPick.background};
                  `}
                >
                  {' '}
                  <i className="fa fa-chevron-left inline-block mr-2" />
                  View All Books{' '}
                </Link>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
    );
  },
);
