/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import classNames from 'classnames';
import { Link } from 'gatsby';

const Button = (props) => {
  const { icon, children, to, external, light, dark, amazon, goodreads } = props;
  const btnClasses = classNames({
    'bg-gray-300 hover:bg-gray-400 w-40 p-3': light,
    'bg-orange-200 text-yellow-800 hover:bg-orange-300 w-40 p-3 hover:shadow-md': goodreads,
    'bg-orange-400 hover:bg-orange-500 w-40 p-3 hover:shadow-md mb-4 text-gray-800': amazon,
    'bg-primary-500 hover:bg-primary-600 w-20 px-4 py-2': dark,
  });

  if (to) {
    return (
      <Link
        className={`${btnClasses} text-center items-center rounded font-mono font-semibold text-sm flex justify-center`}
        to={to}
      >
        {icon && <i className={`fa ${icon} fa-lg mr-4`} />} {children}
      </Link>
    );
  }
  return (
    <a
      className={`${btnClasses} text-center items-center rounded font-mono font-semibold text-sm flex justify-center`}
      href={external}
      rel="sponsored noopener noreferrer"
      target="_blank"
      css={css`
        transition: all 0.2s ease-in;
      `}
    >
      {icon && <i className={`fa ${icon} fa-lg mr-4`} />} {children}
    </a>
  );
};

export default Button;
