import { from as vibrantFrom } from 'node-vibrant';
import { useEffect, useState } from 'react';

function usePalette(src) {
  const [palette, setPalette] = useState({});

  useEffect(() => {
    vibrantFrom(src)
      .getPalette()
      .then((vibrantPalette) => {
        const resultPalette = Object.entries(vibrantPalette).reduce(
          // Set lowercase first chars like react-palette
          (result, [[firstChar, ...name], color]) => ({
            [firstChar.toLowerCase() + name]: color.hex,
            ...result,
          }),
          {},
        );

        setPalette(resultPalette);
      });
  }, [src]);

  return palette;
}

export default usePalette;
